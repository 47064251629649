import { GetStaticPropsResult } from 'next';

import { IPageMetadata } from '../src/interfaces/nextjs';
import { NotFound } from '../src/views/NotFound/NotFound';

export async function getStaticProps(): Promise<GetStaticPropsResult<IPageMetadata>> {
  return {
    props: {
      _page: 'not_found',
      _pageTitle: 'Page Not Found',
    },
  };
}

export default NotFound;
