import React from 'react';

import { NextPage } from 'next';

import { Heading } from '../../components/Heading/Heading';
import { Text } from '../../components/Text/Text';

export const NotFound: NextPage = () => {
  return (
    <div className="flex justify-center" style={{ minHeight: '60vh' }} data-testid="NotFound">
      <div className="my-16" style={{ maxWidth: '800px' }}>
        <Heading as="h1" className="mb-4">
          Page not found
        </Heading>
        <Text>
          We couldn’t find the page you were looking for. Here are some reasons why this sort of
          thing happens, and some things you can try:
        </Text>
        <ul className="m-0 list-disc p-4">
          <li>
            The link you tried to open goes to the Fishbrain app, and you tried to access it on a
            non-mobile device. Try opening the link from a phone that has the Fishbrain app
            installed.
          </li>
          <li>
            There’s an error in the URL that’s in your web browser. If that’s the case, check that
            the URL is correct and try again. Sometimes typos happen….
          </li>
          <li>
            The page you tried to access was moved or deleted. It’s rare, but it happens. And if you
            think that’s the case, please send us an email at{' '}
            <a href="mailto:support@fishbrain.com">support@fishbrain.com</a> and let us know.
          </li>
        </ul>
        <Text>Thanks!</Text>
      </div>
    </div>
  );
};
